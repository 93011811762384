#authFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#authForm {
  width: 350px;
  padding: 15px 25px;
  box-shadow: 0px 0px 5px #c5c4c5;
  margin: 0px 15px;
}

#authTitle {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 25px;
  color: #999999;
}

.authFieldContainer {
  margin: 10px 0px;
}

.authField {
  background-color: #ebf1f7;
  color: #a0b3ce;
  width: 100%;
  border: 1px solid #c5c4c5;
  padding: 10px 5px;
  border-radius: 5px;
}

.authField::placeholder {
  color: #95aac9;
  opacity: 1;
  text-align: left;
}

.authField:focus {
  outline: none;
}

.requiredFieldError {
  margin: 0px;
  color: rgb(252, 41, 41);
  /* font-size: 19pt; */
}

#authFormFooter {
  text-align: center;
  color: #898989;
}

#loginMessage {
  text-align: center;
  padding: 0.5rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.input-phone-number {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
  padding: .59375rem 1.125rem;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #a0b3ce;
  background-color: #ebf1f7;
  background-clip: padding-box;
  border: 5px solid #ebf1f7;
  appearance: none;
  border-radius: .6rem;
}

.input-phone-number::placeholder {
  color: #95aac9;
  opacity: 1
}

.PhoneInputInput::placeholder {
  color: #95aac9;
  opacity: 1;
  text-align: left;
}

.PhoneInputInput {
  /* This is done to stretch the contents of this component. */
  /* display: block; */
  /* width: 100%;
  height: 100%; */
  /* flex: 1;
  padding-left: 10px;
  padding-right: 0px; */

  /* font-size: .9375rem; */
  /* font-weight: 400; */
  /* line-height: 1.6; */
  color: #a0b3ce;
  background-color: #ebf1f7;
  /* background-clip: padding-box; */
  border: none;
  /* appearance: none; */
  /* border-radius: .6rem; */
  /* background-color: ; */
}